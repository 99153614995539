body {
  font-family: 'Rubik', sans-serif;
}

* {
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: unset;
}

a:hover {
  text-decoration: none;
  color: unset;
}

.btn:focus,.btn:active {
  outline: none !important;
  box-shadow: none;
}

button:focus {
  outline: none;
  outline: none;
}